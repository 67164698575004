<template>
	<div>
		<div class="row" v-if="!actualPurchaseOrder.is_tax">
			<div class="col-md-6">
				<div class="row" v-if="actualPurchaseOrder.status !== 1">
					<div class="col-md-8">
						<b>Proveedor </b>
						<a-icon type="reload" @click="onReloadSuppliers" :style="{ fontSize: '14px', color: 'red' }" v-if="isEditable" />
						<a-select show-search option-filter-prop="children" :filter-option="filterOption" style="width: 100%" :disabled="suppliers.spinnerLoader || !isEditable"
								  v-model="theOrder.supplier" @change="onSelectSupplier" :loading="suppliers.spinnerLoader">
							<a-select-option v-for="(element, index) in suppliersList" :key="index" :value="element.id">({{ element.id }}) - {{ element.full_name }}</a-select-option>
							<div slot="notFoundContent">
								<a-button class="btn btn-block" @click="onAddSupplier">Añadir nuevo proveedor</a-button>
							</div>
						</a-select>
					</div>
					<div class="col-md-4">
						<b>Método de Pago</b>
						<a-select v-model="theOrder.payment_method_index" style="width: 100%" :disabled="suppliers.spinnerLoader || !isEditable">
							<a-select-option v-for="(element, index) in actualRecord.bank" :key="index" :value="index">{{ element.short_name }}</a-select-option>
						</a-select>
					</div>
				</div>
				<div class="row" v-else>
					<div class="col-md-12" v-if="selectedSupplierData && paymentData && actualRecord.tax">
						<table class="table table-bordered table-condensed table-striped m0 mt10">
							<tbody>
								<tr>
									<td width="50%"><b>Razón Social</b></td>
									<td>{{ actualRecord.tax.business_name | replaceIfLogicalFalse('- No establecido -') }}</td>
								</tr>
								<tr>
									<td width="50%"><b>RFC</b></td>
									<td>{{ actualRecord.tax.rfc | replaceIfLogicalFalse('- No establecido -') }}</td>
								</tr>
								<tr>
									<td width="50%"><b>Institución Bancaria</b></td>
									<td>
										{{ paymentData.short_name | replaceIfLogicalFalse('- No establecido -') }}
									</td>
								</tr>
								<tr>
									<td width="50%"><b>Número de cuenta / tarjeta</b></td>
									<td>{{ paymentData.account_number | replaceIfLogicalFalse('- No establecido -') }}</td>
								</tr>
								<tr>
									<td width="50%"><b>{{ isForeignSupplier ? 'Código SWIFT' : 'CLABE interbancaria' }}</b></td>
									<td>{{ paymentData.clabe_number | replaceIfLogicalFalse('- No establecido -') }}</td>
								</tr>

							</tbody>
						</table>
					</div>
					<div class="col-md-12 text-center" v-else>
						<h3 class="text-muted">- Proveedor sin datos fiscales asociados -</h3>
					</div>
					<div class="col-md-12 pt10" v-if="isNewRecord || (Number(actualPurchaseOrder.status) == 0 && !actualPurchaseOrder.blocked)">
						<b>{{ isForeignSupplier ? 'Cotización / Invoice' : 'Cotización' }}</b>
						<br />
						<div v-if="(isNewRecord || Number(actualPurchaseOrder.status) == 0) && !actualPurchaseOrder.file">
							<a-upload name="file" accept="application/pdf" :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload">
								<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
							</a-upload>
						</div>
						<div v-else>
							<div v-if="actualPurchaseOrder.file">
								<a-button @click="getSecureURL(getFileBy('file').id, 'show')"> <a-icon type="file" /> Visualizar Cotización </a-button>
							</div>
							<div v-else>- No establecido -</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="row">
					<div class="col-md-4">
						<b>Referencia</b>
						<a-input v-model="theOrder.reference" :disabled="!isEditable" />
					</div>
					<div class="col-md-4">
						<b>{{ isForeignSupplier ? 'Cotización / Invoice' : 'Cotización' }}</b>
						<br />
						<div v-if="(isNewRecord) && !fileList.length">
							<a-upload name="file" accept="application/pdf" :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload">
								<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
							</a-upload>
						</div>
						<div v-else>
							<div v-if="actualPurchaseOrder.files">
								<a-button @click="getSecureURL(getFileBy('file').id, 'show')"> <a-icon type="file" /> Visualizar Cotización </a-button>
							</div>
							<div v-else>- No establecido -</div>
						</div>
					</div>
					<div class="col-md-4" v-if="isNewRecord">
						<b>Precarga desde XML</b>
						<div>
							<a-upload name="file" accept="text/xml" :file-list="filePreXML" :remove="handleRemovePreXML" :before-upload="handleXML">
								<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
							</a-upload>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<hr class="hrText" data-content="Orden" />
			</div>
		</div>
		<div v-if="isEditable">
			<div v-for="(element, index) in elements" :key="index">
				<div class="row" v-if="index > 0">
					<div class="col-12">
						<hr class="hrDotted" />
					</div>
				</div>
				<div class="row">
					<div class="col-md-3">
						#{{ index + 1 }}
						<b>Cantidad</b>
						<!-- <a-input v-mask="'####'" v-model="element.quantity" :disabled="!isEditable" /> -->
						<a-input v-mask="'####'" v-model="element.quantity" :disabled="!isEditable" />
					</div>
					<div class="col-md-3">
						<b>Total <small>(con IVA)</small></b>
						<a-input prefix="$" v-money="money" v-model="element.unit_cost" :disabled="!isEditable" />
					</div>
					<div class="col-md-3">
						<b>IVA</b>
						<a-input prefix="$" v-money="money" :value="getElementIVA(index)" :disabled="true" />
					</div>
					<div class="col-md-3">
						<b>Importe <small>(sin IVA)</small></b>
						<a-input prefix="$" v-money="money" :value="getElementTotal(index)" :disabled="true" />
					</div>
				</div>
				<div class="row pt14">
					<div class="col-md-2">
						<b>Unidad de medida</b>
						<a-select v-model="element.measurement_unit" style="width: 100%" :disabled="!isEditable">
							<a-select-option value="piece">Pieza</a-select-option>
							<a-select-option value="service">Servicio</a-select-option>
						</a-select>
					</div>
					<div class="col-md-5">
						<b>Descripción</b>
						<a-input v-model="element.description" :disabled="!isEditable" />
					</div>
					<div class="col-md-5">
						<b>Link de referencia</b><br />
						<a-input v-model="element.link" :disabled="!isEditable" />
						<small v-if="validateURL(element.link)"><a :href="element.link" target="new">Visitar link</a></small>
					</div>
					<div class="col-md-12 text-right" v-if="elements.length > 1">
						<span class="pointer" style="color: red" @click="onDeleteElement(index)">Eliminar partida</span>
					</div>
				</div>
			</div>
		</div>
		<div class="row" v-else>
			<div class="col-md-12">
				<a-table :columns="columns" :dataSource="elements" :pagination="false">
					<div slot="quantity" slot-scope="record">
						<div class="text-left">{{ record.quantity }}x {{ record.measurement_unit == 'piece' ? 'pieza' : 'servicio' }}</div>
					</div>
					<div slot="description" slot-scope="record">
						<div class="text-left">
							{{ record.description }}
							<div v-if="record.link">
								<span class="pointer" @click="onLinkPress(record.link)"><a-icon type="login" /> Abrir link</span>
							</div>
						</div>
					</div>
					<div slot="amount" slot-scope="record">{{ numeral(record.unit_cost).format('$0,0.00') }}</div>
					<div slot="total" slot-scope="record">
						{{ numeral(numeral(record.unit_cost).value() * numeral(record.quantity).value()).format('$0,0.00') }}
					</div>
				</a-table>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<hr class="hrDotted" />
			</div>
			<div class="col-md-10 offset-md-1 text-center">
				<div class="row">
					<div class="col-md-4 text-muted" v-if="!isForeignSupplier && !actualPurchaseOrder.is_tax">
						<div style="font-size: 40px">Subtotal</div>
						<div style="font-size: 20px" v-if="discountType == 'before'">{{ numeral(getTotalDiscount).format('$0,0.00') }}</div>
						<div style="font-size: 20px" v-else>{{ numeral(getTotal / 1.16).format('$0,0.00') }}</div>
					</div>
					<div class="col-md-4" v-if="!isForeignSupplier && !actualPurchaseOrder.is_tax">
						<div style="font-size: 40px">IVA</div>
						<div style="font-size: 20px;  color: #ff6400">{{ numeral(getIVA).format('$0,0.00') }}</div>
					</div>
					<div class="col-md-4">
						<div style="font-size: 40px">Total</div>
						<div style="font-size: 20px; color: rgb(31,161,52)">{{ getGrandTotal }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row pt20" v-if="!actualPurchaseOrder.is_tax">
			<div class="col-md-4 text-left" v-if="theOrder.satPaymentMethod == 'NEXT'">
				<b>Fecha de pago</b>
				<a-date-picker v-model="theOrder.paymentDate" :value-format="'YYYY-MM-DD'" :format="'YYYY-MM-DD'" placeholder="Selecciona la fecha" style="width: 100%" :disabled="!isEditable" />
			</div>
		</div>
		<div class="row pt20" v-if="theOrder.satPaymentMethod == 'PPD'">
			<div class="col-md-12">
				<hr class="hrText" data-content="♦" />
			</div>
			<div class="col-md-12">
				<div class="text-center" style="font-size: 30px">Factura general</div>
			</div>
			<div class="col-md-6 text-center">
				<b>Factura PDF</b>
				<br />
				<a-upload v-if="!getFileBy('globalPDF')" name="file" accept="application/pdf" :file-list="filePDF" :remove="handleRemovePDF" :before-upload="beforeUploadPDF">
					<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
				</a-upload>
				<a-button v-else @click="getSecureURL(getFileBy('globalPDF').id, 'show')"> <a-icon type="file" /> Visualizar </a-button>
			</div>
			<div class="col-md-6 text-center">
				<b>Factura XML</b>
				<br />
				<a-upload v-if="!getFileBy('globalXML')" name="file" accept="text/xml" :file-list="fileXML" :remove="handleRemoveXML" :before-upload="beforeUploadXML">
					<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
				</a-upload>
				<a-button v-else-if="getFileBy('globalXML')" @click="getSecureURL(getFileBy('globalXML').id, 'download')"> <a-icon type="download" /> Descargar </a-button>
				<div v-else>- No establecido -</div>
			</div>
			<div class="col-md-12 text-center pt10" v-if="!getFileBy('globalXML') && !getFileBy('globalXML')">
				<a-button class="ml5 btn btn-success" icon="file" type="success" @click="setGlobalInvoice">Adjuntar factura global</a-button>
			</div>

			<div class="col-md-12">
				<hr class="hrText" data-content="♦" />
			</div>
			<div :class="partialitiesClass" v-for="(element, index) in partialities" :key="index">
				<div class="text-center" style="font-size: 30px">Pago #{{ index + 1 }}</div>
				<hr />
				<div>
					<b>Total <small>(con IVA)</small></b>
					<a-input prefix="$" v-money="money" v-model="element.total" :disabled="!isEditable" @change="onChangeTotalPPD(index, $event)" />
				</div>
				<div class="pt10">
					<b>IVA</b>
					<a-input prefix="$" v-money="money" :value="element.iva" disabled />
				</div>
				<div class="pt10">
					<b>Monto <small>(sin IVA)</small></b>
					<a-input prefix="$" v-money="money" :value="element.amount" disabled />
				</div>
				<div class="pt10">
					<b>Fecha de pago</b>
					<a-date-picker v-model="element.paymentDate" :value-format="'YYYY-MM-DD'" :format="'YYYY-MM-DD'" :disabled="!isEditable" @change="onChangePaymentDate(index, $event)"
								   placeholder="Selecciona la fecha" style="width: 100%" />
				</div>
				<div v-if="Number(actualPurchaseOrder.status) >= 1">
					<div v-if="index == 0 || partialities[index - 1].paymentReceipt">
						<hr class="hrText" data-content="Pago" />
						<b>Comprobante</b>
						<div>
							<a-upload v-if="!element.paymentReceipt" name="file" accept="application/pdf,image/*" :file-list="filePayment" :remove="handleRemovePayment"
									  :before-upload="beforeUploadPayment">
								<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
							</a-upload>
							<a-button v-else @click="getSecureURL(element.paymentReceipt, 'show')"> <a-icon type="file" /> Visualizar </a-button>
						</div>
					</div>
				</div>
				<div v-if="(index == 0 && element.paymentReceipt) || (element.paymentReceipt && partialities[index - 1].pdf)">
					<hr class="hrText" data-content="Complemento de pago" />
					<div>
						<div>
							<div>
								<b>Archivo PDF</b>
								<br />
								<a-upload v-if="!element.pdf" name="file" accept="application/pdf" :file-list="filePDF" :remove="handleRemovePDF" :before-upload="beforeUploadPDF">
									<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
								</a-upload>
								<a-button v-else @click="getSecureURL(element.pdf, 'show')"> <a-icon type="file" /> Visualizar </a-button>
							</div>
							<div class="pt10">
								<b>Archivo XML</b>
								<br />
								<a-upload v-if="!element.xml" name="file" accept="text/xml" :file-list="fileXML" :remove="handleRemoveXML" :before-upload="beforeUploadXML">
									<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
								</a-upload>
								<a-button v-else-if="element.xml" @click="getSecureURL(element.xml, 'download')"> <a-icon type="download" /> Descargar </a-button>
								<div v-else>- No establecido -</div>
							</div>
							<div class="pt10 text-center" v-if="!element.pdf && !element.xml">
								<a-button class="ml5 btn btn-success" icon="file" type="success" @click="requestValidation">Adjuntar factura</a-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-3" v-if="actualPurchaseOrder.is_tax">
				<hr class="hrText" data-content="Formato de Pago" />
				<a-upload v-if="!getFileBy('file')" name="file" accept="application/pdf" :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload">
					<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
				</a-upload>
				<div v-else>
					<a-button @click="getSecureURL(getFileBy('file').id, 'show')"> <a-icon type="file" /> Visualizar </a-button>
				</div>
			</div>
			<div class="col-md-3 text-center" v-if="Number(actualPurchaseOrder.status) > 0 && theOrder.satPaymentMethod != 'PPD'">
				<hr class="hrText" data-content="Comprobante de pago" />
				<a-upload v-if="!getFileBy('paymentReceipt')" name="file" accept="application/pdf,image/*" :file-list="filePayment" :remove="handleRemovePayment" :before-upload="beforeUploadPayment">
					<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
				</a-upload>
				<a-button v-else @click="getSecureURL(getFileBy('paymentReceipt').id, 'show')"> <a-icon type="file" /> Visualizar </a-button>
			</div>
			<div class="col-md-6" v-if="!isNewRecord && !actualPurchaseOrder.is_tax">
				<hr class="hrText" data-content="Contabilidad" />
				<div class="row">
					<div class="col-md-6 text-center">
						<b v-if="!showXML">Factura / Ticket de compra</b>
						<b v-else>Ticket de compra / vale azul</b>
						<br />
						<div v-if="Number(actualPurchaseOrder.status) > 2 && actualPurchaseOrder.is_tax && !getFileBy('pdf')">- No establecido -</div>
						<div v-else>
							<a-upload v-if="!getFileBy('pdf')" name="file" accept="application/pdf" :file-list="filePDF" :remove="handleRemovePDF" :before-upload="beforeUploadPDF">
								<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
							</a-upload>
							<a-button v-else @click="getSecureURL(getFileBy('pdf').id, 'show')"> <a-icon type="file" /> Visualizar </a-button>
						</div>
					</div>
					<div class="col-md-6 text-center" v-if="!showXML && !isForeignSupplier">
						<b>Factura XML</b>
						<br />
						<a-upload v-if="!getFileBy('xml') && Number(actualPurchaseOrder.status) < 3" name="file" accept="text/xml" :file-list="fileXML" :remove="handleRemoveXML"
								  :before-upload="beforeUploadXML">
							<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
						</a-upload>
						<a-button v-else-if="getFileBy('xml')" @click="getSecureURL(getFileBy('xml').id, 'download')"> <a-icon type="download" /> Descargar </a-button>
						<div v-else>- No establecido -</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-12">
			<hr class="hrText" />
		</div>
		<div class="row">
			<div class="col-md-4 text-left">
				<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">Regresar</a-button>
			</div>
			<!-- BOTONES POR ESTATUS -->
			<div class="col-md-8 text-right">
				<a-button class="btn btn-primary" icon="plus" @click="addElement(true)" v-if="!actualPurchaseOrder.is_tax && (isEditable || isNewRecord)">Añadir partida</a-button>
				<a-button class="ml5 btn btn-primary" icon="backward" @click="onChangeStatus(0)" v-if="Number(actualPurchaseOrder.status) == 1"> Regresar ODC a cotización </a-button>
				<a-button class="ml5 btn btn-primary" icon="backward" @click="onChangeStatus(-2)" v-if="Number(actualPurchaseOrder.status) == 3"> Regresar ODC a pagadas</a-button>
				<a-button class="ml5 btn btn-success" icon="save" type="success" @click="onCreateOrder" v-if="isNewRecord">Generar ODC</a-button>
				<a-button class="ml5 btn btn-success" icon="save" type="success" @click="onCreateOrder" v-else-if="!actualPurchaseOrder.blocked">Generar ODC</a-button>
				<a-button class="ml5 btn btn-success" icon="forward" type="success" @click="updatePaymentReceipt"
						  v-if="Number(actualPurchaseOrder.status) == 1 || Number(actualPurchaseOrder.status) == 1.1">{{
							theOrder.satPaymentMethod == 'PPD' ? 'Adjuntar comprobante de pago' : `Pasar ODC a
					pagadas` }}</a-button>
			</div>
		</div>
		<a-modal width="65%" :visible="modalSupplier.visible" :footer="null" title="Registro de Proveedor" :closable="true" @cancel="handleCancel">
			<suppliersDetail class="pt0" :v-if="modalSupplier.visible" :supplierId="modalSupplier.selectedSupplier" @ok="supplierSelected" @close="handleCancel" />
		</a-modal>

		<a-modal width="65%" :visible="modalFileVisible" :footer="null" title="Comprobante" :closable="true" @cancel="handleCancelModalFile">
			<div class="row">
				<div class="col-12 text-center">
					<fileViewerComponent :fileURL="fileURL" />
				</div>
				<div class="col-12 text-right">
					<hr class="hrDotted" />
					<a class="ml5 btn btn-primary" :href="fileURL" target="new">Descargar comprobante</a>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
//
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'
import { purchaseOrders } from '@/constants'
import _ from 'lodash'
import Swal from 'sweetalert2'
import numeral from 'numeral'
import utilities from '@/services/utilities'
import suppliersDetail from '@/components/suppliers-detail'
import fileViewerComponent from '@/components/fileViewer'
import xml2js from "xml2js";

const statuses = {
	0: 'En espera',
	1: 'Autorizado',
	2: 'Pagado / Por llegar',
	3: 'ODC Completa',
	4: 'En almacén',
}

export default {
	name: 'insertOrUpdatePurchaseOrder',
	directives: {
		mask,
		money: VMoney,
	},
	components: {
		suppliersDetail,
		fileViewerComponent,
	},
	data() {
		return {
			theOrder: _.cloneDeep(purchaseOrders.structure),
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			money2: {
				decimal: '.',
				thousands: ',',
				precision: 5,
			},
			fileList: [],
			filePDF: [],
			fileXML: [],
			filePayment: [],
			filePreXML: [],
			//
			elements: [],
			modalSupplier: {
				visible: false,
				selectedSupplier: null,
			},
			showXML: false,
			searchIndex: 0,
			generalDiscount: 0,
			discountType: '',
			columns: [
				{
					title: 'Cantidad',
					align: 'center',
					scopedSlots: { customRender: 'quantity' },
				},
				{
					title: 'Descripción',
					align: 'center',
					scopedSlots: { customRender: 'description' },
				},
				{
					title: 'Costo unitario',
					align: 'center',
					scopedSlots: { customRender: 'amount' },
				},
				{
					title: 'Total',
					align: 'center',
					scopedSlots: { customRender: 'total' },
				},
			],
			partialities: [],
			partialitiesClass: '',
			totalPartialities: 0,
		}
	},
	computed: {
		...mapState(['suppliers']),
		...mapGetters('suppliers', ['suppliersList', 'actualRecord']),
		...mapGetters('purchaseOrders', ['actualPurchaseOrder', 'modalFileVisible', 'fileURL']),
		...mapGetters('auth', ['userData']),
		...mapGetters('taxRegimes', ['taxRegimesList']),
		getGrandTotal() {
			return numeral(this.getTotal - utilities.objectValidate(this.actualPurchaseOrder, 'total_impuestos_retenidos', 0)).format('$0,0.00')
		},
		userPermition() {
			//
			let allowedUserPermition = [1, 11, 27, 8]
			return !!allowedUserPermition.includes(this.userData.user_id)
		},
		isNewRecord() {
			return utilities.objectValidate(this.$route, 'params.id', false) && this.$route.params.id == 'create'
		},
		isEditable() {
			return !this.actualPurchaseOrder.total
		},
		selectedSupplierData() {
			return typeof this.theOrder.payment_method_index != 'string' ? true : false
		},
		paymentData() {
			if (!this.actualPurchaseOrder.is_tax && this.actualRecord && this.selectedSupplierData) {
				return this.actualRecord.bank[this.theOrder.payment_method_index]
			}
			return {
				paymentMethod: 0,
				bank: 0,
				bankName: '',
				beneficiary: '',
				accountNumber: '',
				clabe: '',
				rfc: '',
				comments: '',
			}
		},
		getIVA() {
			return (this.getTotal / 1.16) * 0.16
		},
		getTotal() {
			let total = 0

			this.elements.forEach((element, index) => {
				total += numeral(element.quantity).value() * numeral(element.unit_cost).value()
			})

			return total
		},
		statuses() {
			let statusList = {}
			Object.keys(statuses).forEach((key) => {
				if (key && statuses[key] != undefined) {
					statusList[key] = {
						label: statuses[key],
						disabled: key >= this.actualPurchaseOrder.status ? false : true,
					}
				}
			})
			return statusList
		},
		hasInvoice() {
			if (utilities.objectValidate(this.paymentData, 'payment_method', false) && Number(this.paymentData.payment_method) == 1) {
				return !!this.filePDF.length || this.getFileBy('pdf')
			} else {
				return !!((this.filePDF.length || this.getFileBy('pdf')) && (this.fileXML.length || this.getFileBy('xml')))
			}
		},
		getTotalDiscount() {
			return numeral(this.getTotal / 1.16).value() - numeral(this.generalDiscount).value()
		},
		getIVADiscount() {
			return this.getTotalDiscount * 0.16
		},
		hasPDf() {
			return this.fileURL.toLowerCase().split('.pdf').length == 2 ? true : false
		},
		isForeignSupplier() {
			return utilities.objectValidate(this.actualRecord, 'general.nationality', false) && this.actualRecord.general.nationality == 'foreign'
		},
	},
	beforeMount() {
		this.onReloadSuppliers()
		if (!this.taxRegimesList.length) {
			this.$store.dispatch('taxRegimes/GET')
		}
	},
	mounted() {
		//
		if (this.isNewRecord) {
			this.addElement(false)
		} else {
			setTimeout(() => {
				if (!utilities.objectValidate(this.actualPurchaseOrder, 'id', false)) {
					this.$store.dispatch('purchaseOrders/GET_ONE', this.$route.params.id)
					setTimeout(() => {
						this.onSelectSupplier(this.actualPurchaseOrder.supplier_id)
					}, 1000);
				} else {
					this.setData(this.actualPurchaseOrder)
				}
			}, 500)
		}
	},
	destroyed() {
		this.$store.commit('purchaseOrders/SET_STATE', {
			actualPurchaseOrder: {},
		})
		this.$store.commit('suppliers/SET_STATE', {
			actualRecord: {},
		})
	},
	methods: {
		moment,
		numeral,
		supplierSelected(payload) {
			this.theOrder.supplier = payload.supplier_id
			// console.log('supplierSelected::payload', payload);
		},
		addElement(requireConfirmation) {
			if (requireConfirmation) {
				let errors = []
				this.elements.forEach((element, index) => {
					let item = index + 1
					if (element.quantity == '') {
						errors.push(`- Se requiere cantidad en la partida #${item}`)
					}
					if (element.measurement_unit == '') {
						errors.push(`- Se requiere unidad de medida en la partida #${item}`)
					}
					if (element.description == '') {
						errors.push(`- Se requiere descripción en la partida #${item}`)
					}
					if (numeral(element.unit_cost).value() == 0) {
						errors.push(`- Se requiere costo unitario en la partida #${item}`)
					}
				})
				if (errors.length) {
					Swal.fire({
						title: 'Ordenes de compra',
						html: errors.join('<br>'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
					return false
				}
			}
			this.elements.push(_.cloneDeep(purchaseOrders.order))
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		confirm() {
			Swal.fire({
				title: 'Atención',
				html: `¿Deseas autorizar el gasto de esta ODC?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('purchaseOrders/UPDATE_STATUS', {
						id: this.$route.params.id,
						status: 1,
					})
				}
			})
		},

		updatePaymentReceipt() {
			let errors = []
			if (this.filePayment.length == 0) {
				errors.push(`- Debes adjuntar comprobante de pago`)
			}

			if (errors.length) {
				Swal.fire({
					title: 'Ordenes de compra',
					html: errors.join('<br>'),
					icon: 'error',
					confirmButtonText: 'Ok',
				})
				return false
			}

			Swal.fire({
				title: 'Atención',
				html: `Confirmo que he realizado el pago correspondiente de la ODC`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					const { filePayment } = this
					let payment_method = this.actualPurchaseOrder.is_tax ? 2 : JSON.parse(this.actualPurchaseOrder.payment_method_data).payment_method
					let field = 'paymentReceipt'
					let totalPayment = this.actualPurchaseOrder.total - this.actualPurchaseOrder.total_impuestos_retenidos

					let formDataPayment = new FormData()
					formDataPayment.append('file', filePayment[0])
					formDataPayment.append('isForeignSupplier', Boolean(this.isForeignSupplier))
					formDataPayment.append('is_tax', Boolean(this.actualPurchaseOrder.is_tax))
					formDataPayment.append('purchase_order_id', this.$route.params.id)
					formDataPayment.append('payment_method', payment_method)

					if (this.theOrder.satPaymentMethod == 'PPD') {
						let paymentReceipts = this.partialities.filter((e) => e.paymentReceipt).length || 0
						formDataPayment.append('index_ppd_file', paymentReceipts)
						totalPayment = this.partialities[paymentReceipts].total
					}

					formDataPayment.append('total', totalPayment)
					this.$store.dispatch('purchaseOrders/UPLOAD_FILES', { field, formDataPayment, onlyReloadRecord: true })
				}
			})
		},
		setGlobalInvoice() {
			let errors = []
			if (this.filePDF.length == 0) {
				errors.push(`- Debes adjuntar archivo PDF de la factura general`)
			}

			if (this.filePDF.length == 0 || this.fileXML.length == 0) {
				errors.push(`- Debes adjuntar archivo XML de la factura general`)
			}

			if (errors.length) {
				Swal.fire({
					title: 'Ordenes de compra',
					html: errors.join('<br>'),
					icon: 'error',
					confirmButtonText: 'Ok',
				})
				return false
			}

			Swal.fire({
				title: 'Atención',
				html: `Confirmo que los archivos son correspondientes a la ODC`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					// let formDataPDF = false
					// let formDataXML = false
					// let field = 'globalInvoice'

					// if (this.filePDF.length) {
					// 	const { filePDF } = this
					// 	formDataPDF = new FormData()
					// 	formDataPDF.append('file', filePDF[0])
					// 	formDataPDF.append('purchase_order_id', this.$route.params.id)
					// }

					// if (this.fileXML.length) {
					// 	const { fileXML } = this
					// 	formDataXML = new FormData()
					// 	formDataXML.append('file', fileXML[0])
					// 	formDataXML.append('purchase_order_id', this.$route.params.id)
					// }

					// console.log({ formDataPDF, formDataXML })
					this.$store.dispatch('purchaseOrders/UPLOAD_FILES', { field, formDataPDF, formDataXML, onlyReloadRecord: true })
				}
			})
		},
		requestValidation() {
			let errors = []

			if (this.theOrder.satPaymentMethod == 'PUE') {
				if (!this.actualPurchaseOrder.is_tax) {
					if (Number(this.actualPurchaseOrder.status) == 2 && !this.filePDF.length && !this.getFileBy('pdf')) {
						errors.push(`- Debes adjuntar factura, ticket de compra o vale azul`)
					}
					if (!this.isForeignSupplier && this.actualPurchaseOrder.status == 2 && Number(this.paymentData.payment_method) == 2 && !this.fileXML.length && !this.getFileBy('xml')) {
						errors.push(`- Debes adjuntar xml`)
					}
				}
			} else {
				//
				if (!this.filePDF.length && !this.getFileBy('pdf')) {
					errors.push(`- Debes adjuntar archivo PDF de la factura`)
				}
				if (!this.fileXML.length && !this.getFileBy('xml')) {
					errors.push(`- Debes adjuntar archivo XML de la factura`)
				}
			}

			if (errors.length) {
				Swal.fire({
					title: 'Ordenes de compra',
					html: errors.join('<br>'),
					icon: 'error',
					confirmButtonText: 'Ok',
				})
				return false
			}

			Swal.fire({
				title: 'Atención',
				html: `Confirmo que los comprobantes adjuntos corresponden a esta ODC`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					let formDataPDF = false
					let formDataXML = false

					if (this.filePDF.length) {
						const { filePDF } = this
						formDataPDF = new FormData()
						formDataPDF.append('file', filePDF[0])
						formDataPDF.append('purchase_order_id', this.$route.params.id)
					}

					if (this.fileXML.length) {
						const { fileXML } = this
						formDataXML = new FormData()
						formDataXML.append('file', fileXML[0])
						formDataXML.append('purchase_order_id', this.$route.params.id)
					}

					if (this.theOrder.satPaymentMethod == 'PPD') {
						let paymentReceipts = this.partialities.filter((e) => e.pdf).length || 0
						formDataPDF.append('index_ppd_file', paymentReceipts)

						if (this.fileXML.length) {
							formDataXML.append('index_ppd_file', paymentReceipts)
						}
					}
					// console.log({ formDataPDF, formDataXML })
					if (this.actualPurchaseOrder.is_tax) {
						this.$store.dispatch('purchaseOrders/UPDATE_STATUS', {
							id: this.$route.params.id,
							status: 3,
						})
					} else {
						this.$store.dispatch('purchaseOrders/REQUEST_VALIDATION', { formDataPDF, formDataXML, onlyReloadRecord: true })
					}
				}
			})
		},
		setValidation() {
			Swal.fire({
				title: 'Atención',
				html: `Confirmo que los datos y montos expresados en los comprobantes adjuntos corresponden a esta ODC`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('purchaseOrders/UPDATE_STATUS', {
						id: this.$route.params.id,
						status: 4,
					})
				}
			})
		},
		onCreateOrder() {
			let errors = []
			let total = 0

			this.elements.forEach((element, index) => {
				let item = index + 1
				if (element.quantity == '') {
					errors.push(`- Se requiere cantidad en la partida #${item}`)
				}
				if (element.measurement_unit == '') {
					errors.push(`- Se requiere unidad de medida en la partida #${item}`)
				}
				if (element.description == '') {
					errors.push(`- Se requiere descripción en la partida #${item}`)
				}
				if (numeral(element.unit_cost).value() == 0) {
					errors.push(`- Se requiere costo unitario en la partida #${item}`)
				}
				total += numeral(element.quantity).value() * numeral(element.unit_cost).value()
			})

			let orderTotal = this.discountType == 'before' ? numeral(this.getTotalDiscount * 1.16).format('0.00') : total - numeral(this.generalDiscount).value()

			if (!this.actualPurchaseOrder.is_tax && !this.theOrder.supplier) {
				errors.push(`- Se requiere seleccionar proveedor`)
			}
			if (this.selectedSupplierData == '') {
				errors.push(`- Se requiere seleccionar método de pago`)
			}
			if (this.isNewRecord && this.isForeignSupplier && !this.fileList.length) {
				errors.push(`- Debes adjuntar cotización/invoice para su pago`)
			}

			if (this.theOrder.satPaymentMethod == 'NEXT') {
				if (!moment(this.theOrder.paymentDate).isValid()) {
					errors.push(`- Ingresa la fecha de pago de la ODC`)
				}
			}

			if (errors.length) {
				Swal.fire({
					title: 'Ordenes de compra',
					html: errors.join('<br>'),
					icon: 'error',
					confirmButtonText: 'Ok',
				})
				return false
			}

			Swal.fire({
				title: 'Atención',
				html: `Confirmo que deseo generar esta ODC`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					let payload = {}
					let formData = false

					if (this.fileList.length) {
						const { fileList } = this
						formData = new FormData()
						formData.append('file', fileList[0])
					}

					if (!this.actualPurchaseOrder.is_tax) {
						payload = {
							total: orderTotal,
							elements: _.cloneDeep(this.elements),
							supplier_id: this.theOrder.supplier,
							payment_method_index: this.theOrder.payment_method_index,
							payment_method_data: this.actualRecord.bank[this.theOrder.payment_method_index],
							blocked: true,
							general_discount: numeral(this.generalDiscount).value(),
							discount_type: this.discountType,
							status: Number(utilities.objectValidate(this.actualPurchaseOrder, 'status', 0)),
							area: this.theOrder.area,
							advance_ticket: Number(this.theOrder.advance_ticket),
							reference: this.theOrder.reference,
							total_impuestos_retenidos: utilities.objectValidate(this.actualPurchaseOrder, 'total_impuestos_retenidos', 0),
						}

						if (numeral(this.paymentData.banking_institution).value() == 11) {
							payload.cie = this.theOrder.cie
						}

						if (this.theOrder.satPaymentMethod == 'PPD' || this.theOrder.satPaymentMethod == 'NEXT') {
							payload = {
								...payload,
								sat_payment_method: this.theOrder.satPaymentMethod,
								advance_amount: numeral(this.theOrder.advanceAmount).value(),
								credit_payment_date: this.theOrder.paymentDate,
							}
						}

						/// Se añade la definición de pagos al payload
						if (this.theOrder.satPaymentMethod == 'PPD') {
							payload.ppdPayments = _.cloneDeep(this.partialities)
						}
					} else {
						payload = {
							total: orderTotal,
							elements: _.cloneDeep(this.elements),
							blocked: true,
							status: 1,
							is_tax: 1,
						}
					}

					if (this.isNewRecord) {
						this.$store.dispatch('purchaseOrders/CREATE', { payload, formData })
					} else {
						payload = {
							...payload,
							id: this.$route.params.id,
						}
						// console.log('purchaseOrders/UPDATE', { payload, formData })
						this.$store.dispatch('purchaseOrders/UPDATE', { payload, formData })
					}
				}
			})
		},
		beforeUpload(file) {
			this.fileList = [file]
			return false
		},
		beforeUploadPDF(file) {
			this.filePDF = [file]
			return false
		},
		beforeUploadXML(file) {
			this.fileXML = [file]
			return false
		},
		beforeUploadPayment(file) {
			let filename = file.name
			let actualODC = this.$route.params.id
			// verificamos si el archivo a adjuntar cuenta con la estructura X_<ODC>.[pdf, jpg, png]
			// console.log('beforeUploadPayment-->', file);
			if (!filename.includes(`${actualODC}.`)) {
				Swal.fire({
					title: 'Pagos',
					html: `El comprobante de pago no contiene el número de la Orden de Pago (#${actualODC})`,
					showCancelButton: false,
				})
				return false
			}
			this.filePayment = [file]
			return false
		},
		handleRemove() {
			this.fileList = []
		},
		handleRemovePDF() {
			this.filePDF = []
		},
		handleRemoveXML() {
			this.fileXML = []
		},
		handleRemovePayment() {
			this.fileXML = []
		},
		handleRemovePreXML() {
			this.elements = []
			this.filePreXML = []
		},
		onCancel() {
			this.$router.replace('/purchase-orders')
		},
		onReloadSuppliers() {
			this.$store.dispatch('suppliers/GET', true)
		},
		onSelectSupplier(id) {
			this.theOrder.payment_method_index = ''
			this.$store.dispatch('suppliers/GET_ONE', id)
		},
		setData(newData) {
			// console.log('setData::newData-->', newData)
			this.elements = []
			newData.elements.forEach((e) => {
				this.elements.push({
					...e,
					unit_cost: numeral(utilities.objectValidate(e, 'unit_cost', 0)).format('0,0.00'),
				})
			})
			this.theOrder.supplier = _.clone(newData.supplier_id)
			this.theOrder.satPaymentMethod = newData.sat_payment_method
			this.theOrder.advanceAmount = numeral(newData.advance_amount).format('0,0.00')
			this.theOrder.paymentDate = newData.credit_payment_date
			this.theOrder.cie = newData.cie
			this.theOrder.reference = newData.reference
			this.theOrder.area = newData.area
			this.theOrder.advance_ticket = newData.advance_ticket
			this.generalDiscount = numeral(utilities.objectValidate(newData, 'general_discount', 0)).format('0,0.00')
			this.discountType = utilities.objectValidate(newData, 'discount_type', false) ? newData.discount_type : ''
			//

			if (newData.sat_payment_method == 'PPD') {
				// console.log('newData.ppd_payments-->', typeof newData.ppd_payments)
				let ppdPayments = _.cloneDeep(newData.ppd_payments)
				this.theOrder.partialities = ppdPayments.length.toString()
				this.partialities = _.cloneDeep(ppdPayments)
			}

			this.onSelectSupplier(newData.supplier_id)
			setTimeout(() => {
				this.theOrder.payment_method_index = utilities.objectValidate(newData, 'payment_method_index', 0)
			}, 1500)
		},
		validateURL(link) {
			return utilities.isValidURL(link)
		},
		getSecureURL(id, action) {
			this.$store.dispatch('purchaseOrders/GET_FILE_URL', { id, action })
		},
		getElementIVA(index) {
			let amount = numeral(this.elements[index].unit_cost).value()
			let quantity = numeral(this.elements[index].quantity).value()

			return numeral(((amount * quantity) / 1.16) * 0.16).format('0,0.00')
		},
		getElementTotal(index) {
			let amount = numeral(this.elements[index].unit_cost).value()
			let quantity = numeral(this.elements[index].quantity).value()

			return numeral((amount * quantity) / 1.16).format('0,0.00')
		},
		onRejectODC() {
			let inputOptions = {}
			if (this.actualPurchaseOrder.status == 1) {
				inputOptions = {
					1: 'PROVEEDOR - Cambio de precio/cotización',
					2: 'PROVEEDOR - Existencia/capacidad de entrega',
				}
			}
			if (this.actualPurchaseOrder.status == 2) {
				inputOptions = {
					8: 'INTERNO - Los comprobantes no coinciden',
				}
			}
			Swal.fire({
				title: 'Selecciona el cambio de estatus',
				input: 'select',
				inputOptions,
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
				inputPlaceholder: 'Selecciona',
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (!isNaN(value)) {
							value = Number(value)
							if (!value || value < 0) {
								resolve('Es necesario elegir un motivo')
							}
						} else {
							resolve('Es necesario elegir un motivo')
						}
						resolve()
					})
				},
			}).then((result) => {
				if (result.value != '' && result.isConfirmed) {
					//
					this.$store.dispatch('purchaseOrders/UPDATE_STATUS', {
						id: this.$route.params.id,
						status: -1,
						reject_reason_id: Number(result.value),
						elements: this.actualPurchaseOrder.elements.map((e) => e.ie_id),
					})
				}
			})
		},
		handleCancel(data) {
			if (data) {
				this.theOrder.supplier = data.id
				this.onSelectSupplier(this.actualPurchaseOrder.supplier_id)
			}
			this.modalSupplier.visible = false
		},
		handleCancelModalFile() {
			//
			this.$store.commit('purchaseOrders/SET_STATE', {
				modalFileVisible: false,
			})
		},
		onAddSupplier() {
			// console.log('onAddSupplier-->');
			this.theOrder.payment_method_index = ''
			this.$store.commit('suppliers/SET_STATE', {
				actualRecord: {},
			})
			this.modalSupplier.visible = true
		},
		onDeleteElement(index) {
			Swal.fire({
				title: 'Atención',
				html: `¿Deseas eliminar la partida de ODC?`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.elements.splice(index, 1)
				}
			})
		},
		getFileBy(type) {
			if (utilities.objectValidate(this.actualPurchaseOrder, 'files', false)) {
				// console.log(this.actualPurchaseOrder.files.find((e) => e.type == type))
				return this.actualPurchaseOrder.files.find((e) => e.type == type)
			}
			return false
		},
		onSearch(productSKU, index) {
			this.searchIndex = index
			if (productSKU == '' || productSKU.length < 3) {
				Swal.fire({
					title: 'Detalle del Servicio',
					text: 'Debes ingresar texto para la búsqueda',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			} else {
				this.$store.dispatch('products/SEARCH', { searchText: productSKU })
			}
		},
		onChangeStatus(status) {
			//
			let message = ''
			switch (status) {
				case -2:
					message = '¿Deseas regresar ODC a "Pagadas"?'
					break
				case 0:
					message = '¿Deseas regresar ODC a "Cotización"?'
					break
				case 3:
					message = '¿Deseas validar los comprobantes de la ODC?'
					break
				case 4:
					message = '¿Deseas confirmar llegada de la ODC a almacén?'
					break
				default:
					message = ''
					break
			}
			Swal.fire({
				title: 'Atención',
				html: message,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then(async (result) => {
				if (result.value != '' && result.isConfirmed) {
					await this.$store.dispatch('purchaseOrders/UPDATE_STATUS', {
						id: this.$route.params.id,
						status,
					})
				}
			})
		},
		onLinkPress(link) {
			window.open(link)
		},
		onChangePartialities(value) {
			let total = numeral(this.getGrandTotal).value()
			let partialities = []
			this.partialities = []
			value = numeral(value).value()

			for (let i = 1; i <= value; i++) {
				let amount = numeral(total / value / 1.16).format('0,0.00')
				let iva = numeral(numeral(amount).value() * 0.16).format('0,0.00')

				partialities.push({
					amount,
					iva,
					total: numeral(total / value).format('0,0.00'),
					paymentDate: null,
				})
			}
			setTimeout(() => {
				this.partialities = _.cloneDeep(partialities)
			})
		},
		onChangePaymentDate(index, value, method = 'inline') {
			if (index > 0 && moment(value).format('YYYY-MM-DD') <= moment(this.partialities[index - 1].paymentDate).format('YYYY-MM-DD')) {
				if (method == 'inline') {
					Swal.fire({
						title: 'Ordenes de compra',
						text: `La fecha del pago #${index + 1} no puede ser menor o igual al pago previo`,
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				} else {
					return `La fecha del pago #${index + 1} no puede ser menor o igual al pago previo`
				}
			}
		},
		onChangeTotalPPD(index, event) {
			// console.log('onChangeTotalPPD', { index, value: event.target.value })
			let totalAmount = numeral(event.target.value).value()
			this.partialities[index].amount = numeral(totalAmount / 1.16).format('0,0.00')
			this.partialities[index].iva = numeral((totalAmount / 1.16) * 0.16).format('0,0.00')
			this.totalPartialities = numeral(
				numeral(
					this.partialities.reduce((previousValue, currentValue) => {
						return previousValue + numeral(currentValue.total).value()
					}, 0),
				).format('0,0.00'),
			).value()
		},
		handleXML(file) {
			const reader = new FileReader();
			reader.onload = (event) => {
				const xml = event.target.result;
				this.convertXmlToJson(xml);
			};
			reader.readAsText(file);
			this.filePreXML = [file]
			return false
		},
		convertXmlToJson(xml) {
			xml2js.parseString(xml, (err, result) => {
				if (err) {
					console.error(err);
					return;
				}
				// console.log(utilities.objectValidate(result, ['cfdi:Comprobante'], false));

				// Validamos sobre los impuestos retenidos y el régimen del emisor
				if (utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Impuestos', 0, '$', 'TotalImpuestosRetenidos'], false)) {
					let paymentMethod = utilities.objectValidate(result, ['cfdi:Comprobante', '$', 'MetodoPago'], false)
					if (paymentMethod != 'PUE') {
						//
						let taxRegimeCode = utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Emisor', 0, '$', 'RegimenFiscal'], '- No se encuentra el dato -')
						let { code, description } = this.taxRegimesList.find(e => e.code == taxRegimeCode)

						Swal.fire({
							title: 'Validación de XML',
							html: `El XML analizado debe contar con un método de pago dado como PUE debido al régimen que expresa se define como:<br><br>${code} - ${description}`,
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						this.actualPurchaseOrder = {}
						this.filePreXML = []
						return false
					}
					this.actualPurchaseOrder.total_impuestos_retenidos = utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Impuestos', 0, '$', 'TotalImpuestosRetenidos'], 0)
				}

				// console.log(utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Conceptos', 0], false));
				this.elements = []
				let newElements = []

				utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Conceptos', 0, 'cfdi:Concepto'], []).forEach(e => {
					if (utilities.objectValidate(e, '$', false)) {
						let element = e.$
						let quantity = numeral(element.Cantidad).value()

						let newElement = {
							description: element.Descripcion,
							measurement_unit: element.ClaveUnidad == 'H87' ? 'piece' : 'service',
							quantity,
							amount: numeral(element.ValorUnitario).value() * quantity,
							unit_cost: numeral(numeral(element.ValorUnitario).value() * 1.16).format('0,0.00'),
						}
						// console.log('newElement--->', newElement);
						newElements.push(newElement)
					}
					setTimeout(() => {
						this.elements = _.cloneDeep(newElements)
					}, 500);
				})
			});
		},
	},
	watch: {
		actualPurchaseOrder(newData) {
			if (utilities.objectValidate(newData, 'id', false)) {
				this.setData(newData)
				this.filePayment = []
				this.filePDF = []
				this.fileXML = []
			}
		},
		partialities(newData) {
			// console.log('newData-->', newData)
			if (newData) {
				this.partialitiesClass = newData.length == 2 ? 'col-md-6' : newData.length == 3 ? 'col-md-4' : 'col-md-3'
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.table td,
.table th {
	padding: 0.25rem 0.75rem;
	vertical-align: top;
	border-top: 1px solid #dee2e6;
}
</style>