<template>
	<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">

		<a-form class="mb-4" :form="form" @submit="handleSubmit">
			<div class="row">
				<div class="col-md-12">
					<a-form-item :label="$t('suppliersModule.tableHeaders.name')">
						<a-input v-decorator="['full_name', { rules: requiredFileds }]" autocomplete="off" class="text-uppercase" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item :label="$t('suppliersModule.tableHeaders.nacionality')">
						<a-select style="width: 100%" v-decorator="['nationality', { rules: requiredFileds }]">
							<a-select-option value="national">{{ $t('suppliersModule.edit.generalInfo.national') }}</a-select-option>
							<a-select-option value="foreign">{{ $t('suppliersModule.edit.generalInfo.foreign') }}</a-select-option>
						</a-select>
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item :label="$t('suppliersModule.tableHeaders.email')">
						<a-input v-decorator="['email', { rules: emailField }]" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item :label="$t('suppliersModule.tableHeaders.phones')">
						<a-input v-decorator="['phone']" autocomplete="off" />
					</a-form-item>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<hr class="hrText" data-content="Método de pago" />
				</div>
				<div class="col-md-12">
					<div class="row">
						<div :class="form.getFieldValue('payment_method') == '2' ? 'col-md-6' : 'col-md-4'">
							<a-form-item label="Método de pago">
								<a-select v-decorator="['payment_method', { rules: [{ required: true, message: 'Campo requerido' }] }]">
									<a-select-option value="1">Efectivo</a-select-option>
									<a-select-option value="2">Digital</a-select-option>
								</a-select>
							</a-form-item>
						</div>
						<div :class="form.getFieldValue('payment_method') == '2' ? 'col-md-6' : 'col-md-4'">
							<a-form-item label="Institución bancaria">
								<a-select show-search option-filter-prop="children" :filter-option="filterOption" :allowClear="true"
										  v-decorator="['banking_institution', { rules: [{ required: true, message: 'Campo requerido' }] }]">
									<a-select-option v-for="element in localBankingInstitutionsList" :key="element.id" :value="element.id">{{ element.bank_name }} </a-select-option>
								</a-select>
							</a-form-item>
							<small @click="getBankingInstitutions" class="pointer">Recarga</small>
						</div>
						<div :class="form.getFieldValue('payment_method') == '2' ? 'col-md-6' : 'col-md-4'"
							 v-if="form.getFieldValue('banking_institution') != -1 && form.getFieldValue('banking_institution') != 11">
							<a-form-item label="Número de cuenta / tarjeta">
								<a-input v-mask="'################'" v-decorator="['account_number']" autocomplete="off" />
							</a-form-item>
						</div>
						<div :class="form.getFieldValue('payment_method') == '2' ? 'col-md-6' : 'col-md-4'"
							 v-if="form.getFieldValue('payment_method') == '2' && form.getFieldValue('banking_institution') != -1 && form.getFieldValue('banking_institution') != 11">
							<a-form-item :label="form.getFieldValue('nationality') == 'national' ? 'CLABE interbancaria' : 'Código SWIFT'">
								<a-input v-mask="'##################'" v-decorator="['clabe_number']" autocomplete="off" />
							</a-form-item>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12 text-right">
					<a-button class="btn btn-danger" @click="closeModal"> Cancelar </a-button>
					<a-button class="btn btn-success ml5" htmlType="submit">Guardar</a-button>
				</div>
			</div>
		</a-form>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import Swal from 'sweetalert2'
import utilities from '@/services/utilities'
import { mask } from 'vue-the-mask'

export default {
	name: 'supplierDetail',
	directives: {
		mask,
	},
	props: {
		isEditable: {
			type: Boolean,
			default: true,
		},
		showReturnButton: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
			payments: [],
			modal: {
				visible: false,
				title: '',
				payload: {},
			},
			hasReferenceLink: false,
			notApply: false,
			localBankingInstitutionsList: [],
			indexEdit: null,
			fileList: [],
		}
	},
	computed: {
		...mapGetters('bankingInstitutions', ['bankingInstitutionsList']),
		...mapGetters('suppliers', ['spinnerLoader', 'spinnerLoaderLabel']),
		hasPayments() {
			return !!this.payments.length
		},
		requiredFileds() {
			let rules = [
				{
					required: true,
					message: this.$t('general.requiredField'),
				},
			]
			return rules
		},
		emailField() {
			let rules = [
				{
					email: true,
					message: this.$t('general.validEmail'),
				},
			]
			return rules
		},
		getSupplierType() {
			return this.form.getFieldValue('nationality')
		},
	},
	beforeMount() {
		if (!this.bankingInstitutionsList.length) {
			this.getBankingInstitutions()
		}
	},
	mounted() {
		this.localBankingInstitutionsList = _.cloneDeep(this.bankingInstitutionsList)
		this.localBankingInstitutionsList.unshift({
			id: -1,
			bank_name: '- No aplica / Pago interno -',
			short_name: '- No aplica / Pago interno -',
		})
	},
	methods: {
		closeModal() {
			this.$emit('close')
			this.cleanData()
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				// console.log('validateFields-->', values);
				if (!err) {
					let payload = {
						...values,
						section: 'suppliers',
					}
					// Generamos el proveedor
					let supplierData = await this.$store.dispatch('suppliers/CREATE', payload)

					// Asociamos datos de pago
					payload = {
						principal: true,
						banking_institution_id: values.banking_institution > 0 ? values.banking_institution : '',
						beneficiary: "",
						account_number: utilities.objectValidate(values, 'account_number', ''),
						clabe_number: utilities.objectValidate(values, 'clabe_number', ''),
						section: "suppliers/bank-data",
						supplier_id: supplierData.data.supplier_id,
					}

					await this.$store.dispatch('suppliers/CREATE', payload)

					// Regresamos datos al componente
					this.$store.dispatch('suppliers/GET', true).then(() => {
						this.$emit('ok', {
							supplier_id: payload.supplier_id,
						})
						this.closeModal()
					})
				}
			})
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		cleanData() {
			this.form.resetFields()
			this.payments = []
		},
		getBankingInstitutions() {
			this.$store.dispatch('bankingInstitutions/GET')
		},
	},
	watch: {
		bankingInstitutionsList(newData) {
			if (newData) {
				this.localBankingInstitutionsList = _.cloneDeep(this.bankingInstitutionsList)
				this.localBankingInstitutionsList.unshift({
					id: -1,
					bank_name: '- No aplica / Pago interno -',
					short_name: '- No aplica / Pago interno -',
				})
			}
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
<style lang="scss" scoped>
.table td,
.table th {
	padding: 0.25rem 0.75rem;
	vertical-align: top;
	border-top: 1px solid #dee2e6;
}
</style>